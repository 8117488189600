@import 'shared-styles/core-variables-and-helpers';

.toast {
  position: relative;
  height: 45px;
  padding: 10px;
  margin: 20px 0;
  border-radius: 6px;
  font: 700 0.9rem / 1.3rem $base-font-family;
  background: $smr-blue;
  color: white;
  box-shadow: 0px 4px 4px 0px #00000040;

  @media only screen and (min-width: $tablet-large) {
    text-align: center;
  }

  > img {
    margin-right: 14px;
    margin-left: 4px;

    @media only screen and (min-width: $tablet-large) {
      margin-right: 20px;
    }
  }

  > button {
    position: absolute;
    top: 10px;
    right: 20px;
    display: block;
    width: 9px;
    height: 9px;
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;

    img {
      display: block;
      width: 9px;
      height: 9px;
    }
  }
}

.success {
  background: $green;
}

.error {
  background: $red;
}
