@import '../../../shared-styles/core-variables-and-helpers';

.container {
  display: flex;
  padding: 10px;
  min-height: 200px;
  height: inherit;
  margin: 0 0 30px;
  box-shadow: 0px 2px 10px 0px #00000026;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  &:first-child {
    margin-top: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  .vehicleContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid $grey-18;
    padding: 10px;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
      border-right: none;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey-18;
      gap: 20px;
      padding-right: inherit;
    }
  }

  .vehicleRemindersContainer {
    padding: 10px 22px 10px 28px;
    flex: 2;

    @media only screen and (max-width: 768px) {
      padding: 20px 10px 0px 10px;
    }

    .reminderContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 38px;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin-top: 20px;
      }
    }
  }

  .spinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;

  .title {
    font-size: 24px;
    font-weight: 700;
    margin-right: 10px;
    color: $grey-14;
    &.statusActive {
      color: $green;
    }

    &.statusInactive {
      color: $grey-18;
    }
  }
}

.registration {
  font: 800 14px/20px $base-font-bold-variant;
  border-radius: 2px;
  padding: 10px 12px;
  background-color: $reg-yellow;
  color: $black;
}

.vehicleName {
  display: block;
  min-width: 235px;
  margin-bottom: 38px;
  font: 800 36px/48px $base-font-family !important;
  color: $grey-14;
  word-break: break-word;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.statusText {
  margin-top: auto;

  &.statusActive {
    color: $green;
  }

  &.statusInactive {
    color: $grey-18;
  }

  p {
    margin-bottom: 0;
  }
}

.lozenge {
  display: inline-block;
  width: auto;
  padding: 6px 12px 4px;
  margin-top: 20px;
  border: 1px solid $green;
  border-radius: 3px;
  font: 700 0.9rem / 1.3rem $base-font-family;
  color: $green;
}

.confirmationCopy {
  padding: 10px 16px 10px 40px;
  margin-bottom: 20px;
  font: 500 0.9rem / 1.3rem $base-font-family;
  background-color: #eaf0f3;
  color: $blue;
  background-image: url('/assets/images/icons/info-blue.svg');
  background-repeat: no-repeat;
  background-position: 16px 10px;
  background-size: 17px 16px;
}