@import 'shared-styles/core-variables-and-helpers';

.stretchContainer {
  position: relative;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
  margin-bottom: 20px;
  background-color: $grey-4;
}

.container {
  max-width: 1190px !important;
  padding: 20px 0.75rem;
  margin: 0 auto;
  color: $grey-14;

  @media only screen and (min-width: $tablet-large) {
    padding: 40px 0.75rem;
  }
}

.wrapper,
.oneVehicleWrapper {
  padding: 20px 16px 0;
  border-left: 5px solid $red;
  background-color: white;
}

.oneVehicleWrapper {
  width: 100%;

  @media only screen and (min-width: $tablet-large) {
    width: 50%;
  }
}

.header {
  padding-left: 16px;
  margin-bottom: 8px;
  font-size: 1.4rem;
  font-weight: 700;
}

.subheading {
  padding-left: 16px;
  margin-top: 5px;
  font-size: 1rem;
}

.item {
  padding: 14px;
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: $shadow-common;
}

.list,
.listWithThreeColumns {
  margin-top: 20px;

  @media only screen and (min-width: $tablet-large) {
    display: flex;
    flex-wrap: wrap;
  }
}

.list {
  > div {
    @media only screen and (min-width: $tablet-large) {
      width: calc(50% - 12px);

      &:nth-child(odd) {
        margin-right: 20px;
      }
    }
  }
}

.listWithThreeColumns {
  > div {
    @media only screen and (min-width: $tablet-large) {
      width: calc(33.33% - 14px);
      margin-right: 20px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.reg {
  padding: 5px 9px;
  margin-right: 30px;
  border-radius: 4px;
  font: 800 1rem/1.5rem $base-font-bold-variant;
  background-color: $reg-yellow;
  color: $grey-22;
}

.details {
  display: flex;
  flex-direction: column;
}

.make {
  font: 800 1.75rem/2rem $base-font-family-bold;
  color: $grey-14;
}

.model {
  font-size: 0.8rem;
  color: $grey-18;
}

.actions {
  text-align: center;
}

.correctBtn {
  width: 100%;
  padding: 12px 28px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  font: 800 1.2rem/1.5rem $base-font-family-bold;
  background-color: $orange;
  color: white;
}

.changeBtn {
  border: 0;
  outline: 0;
  font: 400 1rem/1.5rem $base-font-family;
  text-decoration: underline;
  background-color: transparent;
  color: $orange;
}
