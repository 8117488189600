@import 'shared-styles/core-variables-and-helpers';

.wrapper {
  padding: 16px 20px 16px 40px;
  margin-bottom: 44px;
  background-color: $background-orange;
  background-image: url('/assets/images/icons/info-orange.svg');
  background-repeat: no-repeat;
  background-position: 16px 16px;
  background-size: 17px 16px;
  font: 400 0.9rem / 1.3rem $base-font-family;
  color: $grey-14;

  p {
    margin-bottom: 0;
    padding-left: 8px;
  }

  .title {
    margin-bottom: 10px;
    padding-left: 0;
    font-weight: 700;
    color: $orange;
  }
}
