@import '../../../shared-styles/core-variables-and-helpers';

.container {
  .vehiclesReminderContainer {
    display: flex;
    gap: 40px;
    margin-bottom: 30px;

    @media only screen and (max-width: 1060px) {
      flex-direction: column-reverse;
    }

    .vehiclesContainer {
      flex: 2;
      .noVehiclesText {
        font-size: 1.2em;
      }
    }
    .remindersContainer {
      flex: 1;

      &.vbmAdjustedPadding {
        @media only screen and (min-width: 1061px) {
          padding-top: 84px;
        }
      }
    }
  }
}

.salutation {
  margin-bottom: 22px;
  font: 700 44px/52px $base-font-family;
  color: $grey-14;
}

.addVehicleBtnContainer {
  width: 45%;

  @media only screen and (max-width: 767px) {
    width: 80%;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }

  button {
    font-size: 1.5rem;
    padding: 0.9rem;
  }
}

.title {
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 10px 0 10px;
  margin: 0;
  font-weight: 700;
  color: $grey-14;

  &:first-child {
    padding: 0 0 10px;
  }
}

.subTitle {
  padding: 0 0 30px;
  margin: 0;
  font: 400 0.9rem / 1.3rem $base-font-family;
  color: $grey-14;
}
