@import '../../../shared-styles/core-variables-and-helpers';
.container {
  position: sticky;
  top: 15vh;

  .borderContainer {
    border: 2px solid $orange-8;
    border-radius: 5px;
    padding: 14px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media only screen and (max-width: 1060px) {
      position: static;
    }

    button {
      font-size: 1.5rem;
      padding: 0.9rem;
    }

    .remindersContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 5px;
      background-color: $grey-16;
      padding: 20px;
      margin-bottom: 20px;

      .noRemindersSet {
        margin: 0;
      }
    }
  }

  .smallPrint {
    color: $grey-14;
    margin: 20px 0px;
    text-align: justify;
  }
}

.title {
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
  font-weight: 700;
  color: $grey-14;

  @media only screen and (min-width: 1061px) {
    padding: 0 20px 10px;
  }
}
