@import 'shared-styles/core-variables-and-helpers';

.pageTitle {
  margin-bottom: 0.5rem;

  h3 {
    margin: 0;
    font: 700 2rem / 2.5rem $base-font-family-bold;
    color: $orange;

    .greyColor {
      font: 700 2.8rem / 3.3rem $base-font-family-bold;
      color: $grey-14;
    }
  }

  .subheader {
    margin-bottom: 30px;
    font: 400 0.9rem / 1.3rem $base-font-family;
  }
}
